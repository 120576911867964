var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.rules == 'none' ? '' : _vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{class:_vm.fieldClass,attrs:{"type":{
      'is-danger': errors[0],
      'is-success': valid,
      'is-pasivo': _vm.pasivo == true
    },"label":_vm.label,"message":errors}},[_c('b-taginput',{attrs:{"autocomplete":"","open-on-focus":"","append-to-body":true,"field":"show","icon-right":_vm.icon ? _vm.icon : 'label',"disabled":_vm.disabled,"placeholder":_vm.placeholder,"data":_vm.dataFiltered},on:{"typing":_vm.emitTyping},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},[_c('template',{slot:"empty"},[_vm._v("No hay resultados")])],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }