<!-- 
Componente usado para agrupar dos botones de tipo radio
-->
<template>
  <div>
    <div class="hidden md:block">
      <b-field class="radio-group ">
        <b-radio-button
          v-for="btn in buttons"
          :key="btn.value"
          v-model="innerValue"
          :native-value="btn.value"
          type="is-primary"
        >
          <b-icon :icon="btn.icon"></b-icon>
          <span>{{ btn.label }}</span>
        </b-radio-button>
      </b-field>
    </div>
    <div class="block md:hidden">
      <b-field class="radio-group">
        <b-radio-button
          v-for="btn in buttons"
          :key="btn.label"
          v-model="innerValue"
          :native-value="btn.value"
          type="is-primary"
          size="is-small"
        >
          <b-icon :icon="btn.icon"></b-icon>
          <span>{{ btn.label }}</span>
        </b-radio-button>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "radioGroup",
  props: {
    buttons: Array,
    value: Boolean
  },
  data() {
    return {
      innerValue: false
    };
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
