<template>
  <div class="columns">
    <div class="column">
      <b-field grouped group-multiline v-if="tagsFiltros.length > 0">
        <p class="pr-2">Filtros activos:</p>
        <div class="control" v-for="(tag, index) in tagsFiltros" :key="index">
          <b-taglist attached>
            <b-tag type="is-dark">{{ tag.filtro }}</b-tag>
            <b-tag
              :type="pasiva ? 'is-pasivo' : 'is-primary'"
              closable
              @close="removeFilter(index)"
            >
              {{ tag.nombre }}
            </b-tag>
          </b-taglist>
        </div>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "TagsFiltros",
  props: {
    tagsFiltros: Array,
    pasiva: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    removeFilter(index) {
      this.$emit("removeFilter", index);
    }
  }
};
</script>
