<template>
  <validation-observer
    ref="observer"
    v-slot="{ valid }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-3xl">Selecciona las lineas para la {{ tipo }}</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <valid-tag-input
          expanded
          class="text-left"
          label="Folios:"
          placeholder="Elige una o varias lineas..."
          rules="required"
          show="id"
          v-model="lineasSeleccionadas"
          :data="lineas"
          :pasivo="pasiva"
        />
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
            expanded
            :disabled="!valid"
            :type="pasiva ? 'is-pasivo' : 'is-primary'"
            @click.native="storeLineasIds"
          >
            Aceptar
        </b-button>
      </section>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidTagInput from '../form/ValidTagInput.vue';

export default {
  name: "ModalReestructuraRenovacion",
  components: {
    ValidationObserver,
    ValidTagInput,
  },
  data() {
    return {
        lineasSeleccionadas: [],
    };
  },
  props: {
    tipo: String,
    lineas: Array,
    pasiva: Boolean,
    cliente: Object,
    grupoCredito: Object,
  },
  watch: {
  },
  computed: {
    // ...mapGetters("garantias", ["getGarantias"]),
  },
  methods: {
    storeLineasIds() {
        // await this.$store.dispatch("lineascredito/setLineasReestructuraRenovacion", this.lineasSeleccionadas);
        this.$router.push({
            name: "crear_linea",
            params: { 
                pasiva: this.pasiva, 
                lineasReestructuraRenovacion: this.lineasSeleccionadas, 
                clienteReestructuraRenovacion: this.cliente,
                grupoReestructuraRenovacion: this.grupoCredito,
                esReestructuraORenovacion: this.tipo,
            }
        });
    }
  }
}
</script>