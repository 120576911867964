var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.rules == 'none' ? '' : _vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{class:_vm.fieldClass,attrs:{"type":{
      'is-danger': errors[0],
      'is-success': valid,
      'is-pasivo': _vm.pasivo == true
    },"label":_vm.label,"message":errors,"custom-class":_vm.labelClass}},[(!_vm.grouped)?_c('b-select',{attrs:{"placeholder":_vm.placeholder,"expanded":_vm.expanded,"disabled":_vm.disabled},on:{"input":_vm.tellParent},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},_vm._l((_vm.data),function(value){return _c('option',{key:value.id,attrs:{"disabled":value['disabled']},domProps:{"value":_vm.deepFind(value, _vm.realValue)}},[_c('span',[_vm._v(_vm._s(_vm.deepFind(value, _vm.show) .toString() .split("|")[0]))]),(_vm.customShow != '')?_c('span',{staticClass:"block"},[_vm._v(" - "+_vm._s(_vm._f("currency")(value[_vm.customShow]))+" ")]):_vm._e()])}),0):_c('b-select',{attrs:{"placeholder":_vm.placeholder,"expanded":_vm.expanded,"disabled":_vm.disabled},on:{"input":_vm.tellParent},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},_vm._l((_vm.data),function(group){return _c('optgroup',{key:group.group,attrs:{"label":group.group}},_vm._l((group.children),function(value,key){return _c('option',{key:value.id,domProps:{"value":_vm.realValue == 'key' ? key : value[_vm.realValue]}},[_vm._v(" "+_vm._s(_vm.show == "key" ? key : value[_vm.show])+" ")])}),0)}),0)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }