<template>
  <ValidationProvider
    :rules="rules == 'none' ? '' : rules"
    v-slot="{ errors, valid }"
  >
    <b-field
      :type="{
        'is-danger': errors[0],
        'is-success': valid,
        'is-pasivo': pasivo == true
      }"
      :label="label"
      :message="errors"
      :class="fieldClass"
    >
            <b-taginput
                autocomplete
                open-on-focus
                :append-to-body="true"
                v-model="innerValue"
                field="show"
                :icon-right="icon ? icon : 'label'"
                :disabled="disabled"
                :placeholder="placeholder"
                :data="dataFiltered"
                @typing="emitTyping"
            >
                <template slot="empty">No hay resultados</template>
            </b-taginput>
        </b-field>

  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "validAutoComplete",
  props: {
    rules: String,
    label: String,
    placeholder: String,
    expanded: {
      type: Boolean,
      default: false
    },
    data: Array,
    show: String,
    value: null,
    fieldClass: String,
    pasivo: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    custom: Boolean,
    icon: String
  },
  components: {
    ValidationProvider
  },
  data: () => ({
    innerValue: "",
    inputValue: "",
  }),
  computed: {
    dataFiltered() {
      if (this.data) {
        return this.data.filter(
          option =>
            option
              .toString()
              .toLowerCase()
              .indexOf(this.inputValue) >= 0
        );
      }
      return [];
    }
  },
  methods: {
    emitTyping(val) {
      this.inputValue = val;
      this.$emit("typing", val);
    },
    resolve(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
