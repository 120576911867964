<template>
  <div>
    <div class="w-full h-80 -mt-8" ref="funnelchart" />
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import spanish from "@/vendors/es_ES";

am4core.useTheme(am4themes_animated);

export default {
  name: "FunnelChart",
  props: {
    data: Array
  },
  data() {
    return {
      datos_nombre: [],
      chart: null
    };
  },
  watch: {
    data() {
      this.chart.dispose();
      this.createChart();
    }
  },
  methods: {
    createChart() {
      this.chart = am4core.create(
        this.$refs.funnelchart,
        am4charts.SlicedChart
      );
      this.chart.data = this.data;
      this.chart.language.locale = spanish;
      var series1 = this.chart.series.push(new am4charts.FunnelSeries());
      series1.orientation = "horizontal";
      series1.dataFields.value = "value";
      series1.dataFields.category = "title";
      series1.labels.template.disabled = true;
      series1.slices.template.events.on("hit", ev => {
        this.$emit("click", ev.target.dataItem._dataContext.title);
      });
    }
  },
  mounted() {
    //Se crea el container el cual puede contener maas de una grafica
    this.createChart();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>
