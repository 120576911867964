<template>
  <div class="column is-12" v-if="showing">
    <div
      class="columns has-border-radius p-5 relative overflow-hidden pr-0"
      :class="{
        'has-background-pasivo-04': pasiva == 1,
        'has-background-primary-04': pasiva != 1,
        'has-background-arrendamiento-light': arrendamiento
      }"
    >
      <b-button
        icon-left="times"
        class="absolute top-0 right-0 m-2 z-10"
        type="is-text"
        @click="hideBanner"
      />
      <div class="column is-9 md:pr-32">
        <p
          class="text-left font-bold capitalize mb-3"
          :class="[
            {
              'has-text-primary': pasiva != 1,
              'has-text-pasivo': pasiva == 1,
              'has-text-arrendamiento': arrendamiento,
              'title is-6': tituloSize == null
            },
            tituloSize
          ]"
        >
          {{ titulo }}
        </p>
        <p class="text-left has-text-grey-darker mt-2">
          {{ descripcion }}
        </p>
        <div class="text-left mt-2">
          <b-button
            v-if="hasBtn"
            :type="
              pasiva 
                ? 'is-pasivo' 
                : arrendamiento ? 'is-arrendamiento' : 'is-primary'
              "
            @click="$emit('pushbtn')"
          >
            {{ btnTitle }}
          </b-button>
        </div>
      </div>
      <div class="column is-3">
        <b-image
          :src="img ? img : '/images/disposiciones_activas.png'"
          class="is-hidden-mobile m-auto"
          :class="imgClass"
          v-show="pasiva != 1"
        />
        <b-image
          :src="img ? img : '/images/disposiciones_pasivas.png'"
          class="is-hidden-mobile m-auto"
          :class="imgClass"
          v-show="pasiva == 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { setLocalParam, getLocalParam } from "@/vendors/helpers";

export default {
  name: "BienvenidoCard",
  props: {
    pasiva: [Number, Boolean],
    titulo: String,
    descripcion: String,
    hasBtn: {
      type: Boolean,
      default: false
    },
    arrendamiento: {
      type: Boolean,
      default: false
    },
    imgClass: String,
    tituloSize: String,
    btnTitle: String,
    img: [String, null]
  },
  data() {
    return {
      showing: true
    };
  },
  methods: {
    hideBanner() {
      setLocalParam(`banner-${this.$route.name}`, false);
      this.showing = false;
    }
  },
  mounted() {
    if (getLocalParam(`banner-${this.$route.name}`) === false) {
      this.showing = false;
    }
  }
};
</script>
