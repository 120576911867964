<template>
  <ValidationProvider
    :rules="rules == 'none' ? '' : rules"
    v-slot="{ errors, valid }"
  >
    <b-field
      :type="{
        'is-danger': errors[0],
        'is-success': valid,
        'is-pasivo': pasivo == true
      }"
      :label="label"
      :message="errors"
      :class="fieldClass"
      :custom-class="labelClass"
    >
      <b-select
        :placeholder="placeholder"
        v-model="innerValue"
        v-if="!grouped"
        :expanded="expanded"
        :disabled="disabled"
        @input="tellParent"
      >
        <option
          v-for="value in data"
          :key="value.id"
          :value="deepFind(value, realValue)"
          :disabled="value['disabled']"
        >
          <span>{{
            deepFind(value, show)
              .toString()
              .split("|")[0]
          }}</span>
          <span class="block" v-if="customShow != ''">
            - {{ value[customShow] | currency }}
          </span>
        </option>
      </b-select>
      <b-select
        :placeholder="placeholder"
        v-model="innerValue"
        v-else
        :expanded="expanded"
        :disabled="disabled"
        @input="tellParent"
      >
        <optgroup :label="group.group" v-for="group in data" :key="group.group">
          <option
            :value="realValue == 'key' ? key : value[realValue]"
            v-for="(value, key) in group.children"
            :key="value.id"
          >
            {{ show == "key" ? key : value[show] }}
          </option>
        </optgroup>
      </b-select>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { deepFind } from "@/vendors/helpers";

export default {
  name: "validSelect",
  props: {
    rules: String,
    label: String,
    placeholder: String,
    expanded: {
      type: Boolean,
      default: false
    },
    data: Array,
    show: String,
    realValue: String,
    value: null,
    fieldClass: String,
    labelClass: String,
    pasivo: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    grouped: {
      type: Boolean,
      default: false
    },
    customShow: {
      type: String,
      default: ""
    }
  },
  components: {
    ValidationProvider
  },
  data: () => ({
    innerValue: null,
    deepFind: deepFind
  }),
  methods: {
    tellParent(newVal) {
      this.$emit("directInput", newVal);
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value !== null) {
      this.innerValue = this.value;
    }
  }
};
</script>
