var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showing)?_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"columns has-border-radius p-5 relative overflow-hidden pr-0",class:{
      'has-background-pasivo-04': _vm.pasiva == 1,
      'has-background-primary-04': _vm.pasiva != 1,
      'has-background-arrendamiento-light': _vm.arrendamiento
    }},[_c('b-button',{staticClass:"absolute top-0 right-0 m-2 z-10",attrs:{"icon-left":"times","type":"is-text"},on:{"click":_vm.hideBanner}}),_c('div',{staticClass:"column is-9 md:pr-32"},[_c('p',{staticClass:"text-left font-bold capitalize mb-3",class:[
          {
            'has-text-primary': _vm.pasiva != 1,
            'has-text-pasivo': _vm.pasiva == 1,
            'has-text-arrendamiento': _vm.arrendamiento,
            'title is-6': _vm.tituloSize == null
          },
          _vm.tituloSize
        ]},[_vm._v(" "+_vm._s(_vm.titulo)+" ")]),_c('p',{staticClass:"text-left has-text-grey-darker mt-2"},[_vm._v(" "+_vm._s(_vm.descripcion)+" ")]),_c('div',{staticClass:"text-left mt-2"},[(_vm.hasBtn)?_c('b-button',{attrs:{"type":_vm.pasiva 
              ? 'is-pasivo' 
              : _vm.arrendamiento ? 'is-arrendamiento' : 'is-primary'},on:{"click":function($event){return _vm.$emit('pushbtn')}}},[_vm._v(" "+_vm._s(_vm.btnTitle)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"column is-3"},[_c('b-image',{directives:[{name:"show",rawName:"v-show",value:(_vm.pasiva != 1),expression:"pasiva != 1"}],staticClass:"is-hidden-mobile m-auto",class:_vm.imgClass,attrs:{"src":_vm.img ? _vm.img : '/images/disposiciones_activas.png'}}),_c('b-image',{directives:[{name:"show",rawName:"v-show",value:(_vm.pasiva == 1),expression:"pasiva == 1"}],staticClass:"is-hidden-mobile m-auto",class:_vm.imgClass,attrs:{"src":_vm.img ? _vm.img : '/images/disposiciones_pasivas.png'}})],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }